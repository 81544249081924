import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="bg-black px-3 py-8 text-white">

      <div className="text-center pb-8">
        <span>© Dott. Francesco Latini - Psicologo | Psicoterapeuta - P.IVA 03661300545</span>
      </div>

      <div className="grid grid-cols-6">
        <div className="col-span-6 md:col-span-4 md:col-start-2">
          <div className="grid grid-cols-2 text-center md:text-left gap-y-4">

            <div className="col-span-2 md:col-span-1">
              <p className="text-2xl">Contatti:</p>
              <ul>
                <li><b>Tel:</b> <a className="no-underline" href="tel:+393922417807">+ 39 392 24 17 807</a></li>
                <li><b>Email:</b> <a className="no-underline" href="mailto:francesco.latini.psy@gmail.com">francesco.latini.psy@gmail.com</a></li>
              </ul>
            </div>

            <div className="col-span-2 md:col-span-1">
              <p className="text-2xl">Info utili:</p>
              <ul>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="/cookie-policy">Cookie Policy</Link></li>
              </ul>
            </div>

          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
