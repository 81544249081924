import React from "react"
import Links from "./Links"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MdMenu } from "react-icons/md"

import { title } from "./navbar.module.css"

const query = graphql`
{
  logo: file(base: {eq: "logo.png"}, sourceInstanceName: {eq: "assets"}) {
    childImageSharp {
      gatsbyImageData(
        layout: FIXED,
        height: 40,
        placeholder: TRACED_SVG,
      )
    }
  }    
}
`;

const Navbar = ({ toggleSidebar }) => {
  const { logo } = useStaticQuery(query);

  return (
    <nav className="flex items-center p-3 bg-white">
      <div className="flex items-center flex-grow">
        <Link to="/">
          <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="logo" />
        </Link>
        <span className={`pl-3 hidden md:inline ${title}`}>Francesco Latini - Psicologo Psicoterapeuta</span>
      </div>
      <button className="lg:hidden" onClick={toggleSidebar} aria-label="Toggle sidebar">
        <MdMenu size="32" />
      </button>
      <Links styleClass="hidden lg:flex" />
    </nav>
  );
}

export default Navbar
