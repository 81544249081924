const links = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Prestazioni",
    url: "/prestazioni",
  },
  {
    id: 3,
    text: "Chi sono",
    url: "/chi-sono",
  },
  {
    id: 4,
    text: "Contatti",
    url: "/contatti",
  },
  {
    id: 5,
    text: "Articoli",
    url: "/articoli",
  },
  {
    id: 6,
    text: "Piccoli sollievi",
    url: "/piccoli-sollievi",
  },
];

export default links;
