import React from "react"
import { Link } from "gatsby"
import links from "../constants/links"

const LINK_DEFAULT_CLASSES = "px-4 py-1";
const ACTIVE_LINK_CLASSES = `${LINK_DEFAULT_CLASSES} bg-black text-white`;

const Links = ({ styleClass }) => {
    return <ul className={styleClass ? styleClass : ""}>
        {links.map(
            link => <li key={link.id}>
                <Link
                    to={link.url}
                    // Do not use activeClassName as it cannot handle "/prestazioni" the same of "/prestazioni/"
                    getProps={({ isCurrent, location }) => {
                        if (isCurrent || location.pathname === `${link.url}/`) {
                            return { className: ACTIVE_LINK_CLASSES };
                        }

                        return { className: LINK_DEFAULT_CLASSES };
                    }}
                >
                    {link.text}
                </Link>
            </li>
        )}
    </ul>;
}

export default Links;
