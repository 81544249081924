import React from "react"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import CookieBar from "./CookieBar"

import "./layout.css"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="text-black">
      <header className="sticky top-0 w-full">
        <Navbar toggleSidebar={toggleSidebar} />
      </header>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <main>
        {children}
      </main>
      <Footer />
      <CookieBar />
    </div>
  )
}

export default Layout
