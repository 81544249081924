import React from "react"
import Links from "./Links"
import { MdClose } from "react-icons/md"

import { linkList } from "./sidebar.module.css"

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <aside className={`lg:hidden ${isOpen ? "" : "hidden"} bg-white text-center flex flex-col py-16`}>
      <Links styleClass={`flex-grow ${linkList}`} />
      <button onClick={toggleSidebar} className="self-center" aria-label="Close sidebar">
        <MdClose size="32" />
      </button>
    </aside>
  )
}

export default Sidebar
