import React from "react"
import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { Link } from "gatsby"

const CookieBar = () => {
  const location = useLocation();

  return (
    <CookieConsent
      cookieName={process.env.COOKIE_CONSENT_COOKIE_NAME}
      disableStyles={true}
      onAccept={() => initializeAndTrack(location)}
      containerClasses="fixed bottom-0 w-full p-3 flex justify-between items-center bg-grey text-white"
      buttonText="Accetta"
      buttonClasses="ml-2 p-2 bg-yellow text-grey"
    >
      <p>
        Questo sito utilizza cookie per migliorare funzionalità e prestazioni.
        &nbsp;
        <br className="md:hidden" />
        <Link
          to="/cookie-policy"
          className="underline"
        >
          Maggiori informazioni
        </Link>
      </p>
    </CookieConsent>
  );
}

export default CookieBar